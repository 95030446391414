const ComingSoonOverlay = () => {
  return (
    <div
      className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
      style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
    >
      <div className="text-center text-white">
        <h2 className="font-bold text-5xl mb-4">Coming Soon</h2>
        <p>We are currently pop up only.</p>
        <p>Check the home page for details on next drop.</p>
        <p>Accounts and online ordering coming soon.</p>
      </div>
    </div>
  );
};

export default ComingSoonOverlay;
