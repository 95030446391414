import clsx from 'clsx';
import classes from './Home.module.css';
import { useEffect, useRef, useState } from 'react';
import Navbar from 'src/components/Navbar';
import ProductCard from 'src/components/ProductCard';
import Footer from 'src/components/Footer';
import CollectionCard from 'src/components/CollectionCard';
import { Link } from 'react-router-dom';
import { Card, CardTitle } from 'src/components/base/ui/card';
import Banner from 'src/components/Banner';
import PeopleRunningImage from '../../assets/people-running.jpg';
import MarathonImage from '../../assets/marathon.jpg';
import { collections } from 'src/mock/collections';
import { products } from 'src/mock/products';
import Countdown from 'src/components/Countdown';

const Home = () => {
  const heroRef = useRef<HTMLDivElement>(null);

  const [isHeroVisible, setIsHeroVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const heroDiv = heroRef.current;
      if (heroDiv) {
        const heroRect = heroDiv.getBoundingClientRect();
        const _isHeroVisible = heroRect.top <= 0 && heroRect.bottom >= 0;

        setIsHeroVisible(_isHeroVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  console.log('isHeroVisible', isHeroVisible);

  return (
    <div>
      <Navbar
        className={clsx('fixed w-full', {
          'bg-white': !isHeroVisible,
          '!bg-transparent': isHeroVisible,
          'text-white': isHeroVisible,
        })}
      />
      <div
        ref={heroRef}
        className={clsx(
          classes.hero,
          'flex flex-col justify-center items-center pt-14'
        )}
      >
        <div className="font-medium blur-sm italic text-5xl lg:text-7xl text-center">
          VISION RUNNING
        </div>
      </div>

      <div className="flex flex-col gap-16 py-16">
        <div className="container text-center flex flex-col gap-4">
          <h3 className="font-semibold text-2xl">Next Drop Countdown</h3>
          <Countdown targetDate="2025-01-01T00:00:00" />
          <p>
            Details (when and where*) will appear here a week before the drop.
          </p>
          <p className="text-xs text-gray-500">
            *For the time being all drops will be somewhere in Chicago
          </p>
        </div>

        <div className="container grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4">
          {collections.map((collection, index) => (
            <CollectionCard key={index} {...collection} />
          ))}
        </div>

        <Banner
          title="Chicago"
          image="https://hips.hearstapps.com/hmg-prod/images/young-man-running-outdoors-in-morning-royalty-free-image-1717693611.jpg"
        />

        <div className="container grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4">
          {products.map((product, index) => (
            <ProductCard key={index} {...product} />
          ))}
        </div>

        <div className="container grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
          <Link to="/about/run-club">
            <Card
              className={clsx(
                'flex cursor-pointer relative aspect-square justify-center items-center'
              )}
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${PeopleRunningImage}) no-repeat center center/cover`,
              }}
            >
              <CardTitle className="text-center text-white text-4xl">
                VISION
                <span className="font-thin italic">Virtual Run Club</span>
              </CardTitle>
            </Card>
          </Link>
          <Link to="/about/community">
            <Card
              className={clsx(
                'flex cursor-pointer relative aspect-square justify-center items-center'
              )}
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${MarathonImage}) no-repeat center center/cover`,
              }}
            >
              <CardTitle className="text-center text-white text-4xl">
                COMMUNITY
              </CardTitle>
            </Card>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
